import React, { useState , useEffect } from 'react';
import AOS from 'aos';
import { Link} from "react-router-dom";
import { Button, Col, Menu, Row } from 'antd';
import { MenuUnfoldOutlined, CloseOutlined } from '@ant-design/icons';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'aos/dist/aos.css';

const Case = () => {

    const pathURL = window.location.pathname.split("/")
    const pathURLnow = pathURL[1]

    const [isActive, setActive] = useState(false);
    const handleToggle = () => {
        setActive(!isActive);
    };
    const handleHideMenuMobile = () => {
        setActive(false);
    };

    function SampleNextArrow(props: any) {
        const { className, style, onClick } = props;
        return (
            <div className="img-next" onClick={onClick}>
                <img src="./images/next.png" alt="" />
            </div>
        );
    }

    function SamplePrevArrow(props: any) {
        const { className, style, onClick } = props;
        return (
            <div className="img-prev" onClick={onClick}>
                <img src="./images/prev.png" alt="" />
            </div>
        );
    }

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        autoplay: true,
        slidesToShow: 2,
        slidesToScroll: 1
    };

    useEffect(() => {
        AOS.init();
    },[]);

    return (
        <>
            <header className="header" >
                <div className="container">
                    {pathURLnow === "get-in-touch" ?
                        <>
                            <div className="main-header">
                                <div className="left">
                                    <div className="box-img">
                                        <a href="/">
                                            <img src=".../../images/logo-neww.svg" alt="" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className="main-header">
                                <div className="left">
                                    <div className="box-img">
                                        <a href="/">
                                            <img src="../../images/logo-spield.png" alt="" />
                                        </a>
                                    </div>
                                </div>
                                <div className="right">
                                    <div className='d-desktop'>
                                        <div className="main-menu">
                                            <ul className="list-menu">
                                                <li>
                                                    <a href="/spield-trading">
                                                        About US
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/spield-trading/case">
                                                        CASES
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/spield-trading">
                                                        Services
                                                    </a>
                                                </li>

                                                <li>
                                                    <a href="/spield-trading">
                                                        Solutions
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="butotn-row">
                                            <button type="button" className="btn btn-touch">
                                                <a href="/spield-trading/get-in-touch">
                                                            GET IN TOUCH
                                                        </a>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='d-mobile'>
                                        <Button type="primary" onClick={handleToggle} className="btn-menu-mobile">
                                            <MenuUnfoldOutlined />
                                        </Button>
                                    </div>
                                    <div className="menu-mobile">
                                        <Menu
                                            defaultSelectedKeys={['']}
                                            defaultOpenKeys={['']}
                                            mode="inline"
                                            theme="dark"
                                            className={!isActive ? 'hide-menu menu-mobile-ul' : 'show-menu menu-mobile-ul'}
                                        >
                                            <Menu.Item key="11" className='item-menu-clo'>
                                                <Button type="primary" onClick={handleToggle} className="btn-menu-mobile-close">
                                                    <CloseOutlined/>
                                                </Button>
                                            </Menu.Item>
                                            <Menu.Item key="about-us" icon={''}>
                                                <Link to="/spield-trading" onClick={handleHideMenuMobile} className="a-menu-mobile">
                                                    About US
                                                </Link>
                                            </Menu.Item>
                                            <Menu.Item key="case" icon={''}>
                                                <Link to="/spield-trading/case" onClick={handleHideMenuMobile} className="a-menu-mobile">
                                                    CASES
                                                </Link>
                                            </Menu.Item>
                                            <Menu.Item key="services" icon={''} title="Docs" onClick={handleHideMenuMobile}>
                                                <a href='/spield-trading' className="a-menu-mobile">
                                                    Services
                                                </a>
                                            </Menu.Item>
                                            <Menu.Item key="solutions" icon={''} title="Docs" onClick={handleHideMenuMobile}>
                                                <a href="/spield-trading" className="a-menu-mobile">
                                                    Solutions
                                                </a>
                                            </Menu.Item>
                                            <Menu.Item className='mt-30' key="get-in-touch" icon={''} title="Docs" onClick={handleHideMenuMobile}>
                                                <div className="butotn-row">
                                                    <button type="button" className="btn btn-touch">
                                                        <a href="/spield-trading/get-in-touch">
                                                            GET IN TOUCH
                                                        </a>
                                                    </button>
                                                </div>
                                            </Menu.Item>

                                        </Menu>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </header>
            <div className="wrap-section">
                

                <div className="wrap-cases-participated">
                    <div className="container">
                        <div className='participated-title'>Cases</div>
                        <div className='participated-block'>
                        
                            <Row gutter={16} justify="center">
                                <Col xs={8} sm={4}>
                                    <div className='participated-item'>
                                        <a href='#'><img src='../images/icon-part/near.svg'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item'>
                                        <a href='#'><img src='../images/icon-part/flow.svg'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item'>
                                        <a href='#'><img src='../images/icon-part/solana.svg'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item'>
                                        <a href='#'><img src='../images/icon-part/polygon.svg'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item'>
                                        <a href='#'><img src='../images/icon-part/polkadot.svg'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item'>
                                        <a href='#'><img src='../images/icon-part/secondlive.svg'/></a>
                                    </div>
                                </Col>
                                
                                <Col xs={8} sm={4}>
                                    <div className='participated-item'>
                                        <a href='#'><img src='../images/icon-part/chia.svg'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item'>
                                        <a href='#'><img src='../images/icon-part/avalanche.svg'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item'>
                                        <a href='#'><img src='../images/icon-part/bscs.svg'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item'>
                                        <a href='#'><img src='../images/icon-part/trustwallet.svg'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item'>
                                        <a href='#'><img src='../images/icon-part/vext.svg'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item'>
                                        <a href='#'><img src='../images/icon-part/combo.svg'/></a>
                                    </div>
                                </Col>
                        
                                <Col xs={8} sm={4}>
                                    <div className='participated-item'>
                                        <a href='#'><img src='../images/icon-part/elastos.svg'/></a>
                                    </div>
                                </Col>

                                <Col xs={8} sm={4}>
                                    <div className='participated-item'>
                                        <a href='#'><img src='../images/icon-part/1sol.svg'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item'>
                                        <a href='#'><img src='../images/icon-part/1.svg'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item'>
                                        <a href='#'><img src='../images/icon-part/2.svg'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item'>
                                        <a href='#'><img src='../images/icon-part/3.svg'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item'>
                                        <a href='#'><img src='../images/icon-part/dego.svg'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item'>
                                        <a href='#'><img src='../images/icon-part/4.svg'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item'>
                                        <a href='#'><img src='../images/icon-part/curve.svg'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item'>
                                        <a href='#'><img src='../images/icon-part/5.svg'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item'>
                                        <a href='#'><img src='../images/icon-part/new1.svg'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item'>
                                        <a href='#'><img src='../images/icon-part/new2.svg'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item'>
                                        <a href='#'><img src='../images/icon-part/new3.svg'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item'>
                                        <a href='#'><img src='../images/icon-part/new4.svg'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item'>
                                        <a href='#'><img src='../images/icon-part/new5.svg'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item'>
                                        <a href='#'><img src='../images/icon-part/new6.svg'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item'>
                                        <a href='#'><img src='../images/icon-part/new7.svg'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item'>
                                        <a href='#'><img src='../images/icon-part/new8.svg'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item'>
                                        <a href='#'><img src='../images/icon-part/new9.svg'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item'>
                                        <a href='#'><img src='../images/icon-part/new10.svg'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item'>
                                        <a href='#'><img src='../images/icon-part/new11.svg'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item'>
                                        <a href='#'><img src='../images/icon-part/new12.svg'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item'>
                                        <a href='#'><img src='../images/icon-part/new13.svg'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item'>
                                        <a href='#'><img src='../images/icon-part/new14.svg'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item'>
                                        <a href='#'><img src='../images/icon-part/new15.svg'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item'>
                                        <a href='#'><img src='../images/icon-part/new16.svg'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item res'>
                                        <a href='#'><img src='../images/icon-part/new-new-1.png'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item'>
                                        <a href='#'><img src='../images/icon-part/new-new-2.jpg'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item res'>
                                        <a href='#'><img src='../images/icon-part/new-new-3.png'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item res'>
                                        <a href='#'><img src='../images/icon-part/new-new-4.png'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item res'>
                                        <a href='#'><img src='../images/icon-part/new-new-5.png'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item res'>
                                        <a href='#'><img src='../images/icon-part/new-new-6.png'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item res'>
                                        <a href='#'><img src='../images/icon-part/new-new-7.jpg'/></a>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className='participated-block-mobile'>
                            <Slider {...settings}>
                                <Row gutter={16} justify="center">
                                    <Col xs={24}>
                                        <div className='participated-item'>
                                            <a href='#'><img src='../images/icon-part/near.svg'/></a>
                                        </div>
                                    </Col>
                                    <Col xs={24}>
                                        <div className='participated-item'>
                                            <a href='#'><img src='../images/icon-part/solana.svg'/></a>
                                        </div>
                                    </Col>
                                </Row>
                                <Row gutter={16} justify="center">
                                    <Col xs={24}>
                                        <div className='participated-item'>
                                            <a href='#'><img src='../images/icon-part/polygon.svg'/></a>
                                        </div>
                                    </Col>
                                    <Col xs={24}>
                                        <div className='participated-item'>
                                            <a href='#'><img src='../images/icon-part/polkadot.svg'/></a>
                                        </div>
                                    </Col>
                                </Row>
                                <Row gutter={16} justify="center">
                                    <Col xs={24}>
                                        <div className='participated-item'>
                                            <a href='#'><img src='../images/icon-part/secondlive.svg'/></a>
                                        </div>
                                    </Col>
                                    <Col xs={24}>
                                        <div className='participated-item'>
                                            <a href='#'><img src='../images/icon-part/flow.svg'/></a>
                                        </div>
                                    </Col>
                                </Row>
                                <Row gutter={16} justify="center">
                                    <Col xs={24}>
                                        <div className='participated-item'>
                                            <a href='#'><img src='../images/icon-part/chia.svg'/></a>
                                        </div>
                                    </Col>
                                    <Col xs={24}>
                                        <div className='participated-item'>
                                            <a href='#'><img src='../images/icon-part/avalanche.svg'/></a>
                                        </div>
                                    </Col>
                                </Row>
                                <Row gutter={16} justify="center">
                                    <Col xs={24}>
                                        <div className='participated-item'>
                                            <a href='#'><img src='../images/icon-part/bscs.svg'/></a>
                                        </div>
                                    </Col>
                                    <Col xs={24}>
                                        <div className='participated-item'>
                                            <a href='#'><img src='../images/icon-part/trustwallet.svg'/></a>
                                        </div>
                                    </Col>
                                </Row>
    
                                <Row gutter={16} justify="center">
                                    <Col xs={24}>
                                        <div className='participated-item'>
                                            <a href='#'><img src='../images/icon-part/vext.svg'/></a>
                                        </div>
                                    </Col>
                                    <Col xs={24}>
                                        <div className='participated-item'>
                                            <a href='#'><img src='../images/icon-part/combo.svg'/></a>
                                        </div>
                                    </Col>
                                </Row>
                                <Row gutter={16} justify="center">
                                    <Col xs={24}>
                                        <div className='participated-item'>
                                            <a href='#'><img src='../images/icon-part/elastos.svg'/></a>
                                        </div>
                                    </Col>

                                    <Col xs={24}>
                                        <div className='participated-item'>
                                            <a href='#'><img src='../images/icon-part/1sol.svg'/></a>
                                        </div>
                                    </Col>
                                </Row>

                                <Row gutter={16} justify="center">
                                    <Col xs={24}>
                                        <div className='participated-item'>
                                            <a href='#'><img src='../images/icon-part/1.svg'/></a>
                                        </div>
                                    </Col>
                                    <Col xs={24}>
                                        <div className='participated-item'>
                                            <a href='#'><img src='../images/icon-part/2.svg'/></a>
                                        </div>
                                    </Col>
                                </Row>
                                <Row gutter={16} justify="center">
                                    <Col xs={24}>
                                        <div className='participated-item'>
                                            <a href='#'><img src='../images/icon-part/3.svg'/></a>
                                        </div>
                                    </Col>
                                    <Col xs={24}>
                                        <div className='participated-item'>
                                            <a href='#'><img src='../images/icon-part/dego.svg'/></a>
                                        </div>
                                    </Col>      
                                </Row>
                                <Row gutter={16} justify="center">
                                    <Col xs={24}>
                                        <div className='participated-item'>
                                            <a href='#'><img src='../images/icon-part/4.svg'/></a>
                                        </div>
                                    </Col>
                                    <Col xs={24}>
                                        <div className='participated-item'>
                                            <a href='#'><img src='../images/icon-part/curve.svg'/></a>
                                        </div>
                                    </Col>
                                </Row>
                                
                                <Row gutter={16} justify="center">
                                    <Col xs={24}>
                                        <div className='participated-item'>
                                            <a href='#'><img src='../images/icon-part/5.svg'/></a>
                                        </div>
                                    </Col>
                                    <Col xs={24}>
                                        <div className='participated-item'>
                                            <a href='#'><img src='../images/icon-part/new1.svg'/></a>
                                        </div>
                                    </Col>
                                </Row>

                                <Row gutter={16} justify="center">
                                    <Col xs={24}>
                                        <div className='participated-item'>
                                            <a href='#'><img src='../images/icon-part/new2.svg'/></a>
                                        </div>
                                    </Col>
                                    <Col xs={24}>
                                        <div className='participated-item'>
                                            <a href='#'><img src='../images/icon-part/new3.svg'/></a>
                                        </div>
                                    </Col>
                                </Row>
                                
                                <Col xs={8} sm={4}>
                                    <div className='participated-item'>
                                        <a href='#'><img src='../images/icon-part/new4.svg'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item'>
                                        <a href='#'><img src='../images/icon-part/new5.svg'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item'>
                                        <a href='#'><img src='../images/icon-part/new6.svg'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item'>
                                        <a href='#'><img src='../images/icon-part/new7.svg'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item'>
                                        <a href='#'><img src='../images/icon-part/new8.svg'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item'>
                                        <a href='#'><img src='../images/icon-part/new9.svg'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item'>
                                        <a href='#'><img src='../images/icon-part/new10.svg'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item'>
                                        <a href='#'><img src='../images/icon-part/new11.svg'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item'>
                                        <a href='#'><img src='../images/icon-part/new12.svg'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item'>
                                        <a href='#'><img src='../images/icon-part/new13.svg'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item'>
                                        <a href='#'><img src='../images/icon-part/new14.svg'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item'>
                                        <a href='#'><img src='../images/icon-part/new15.svg'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item'>
                                        <a href='#'><img src='../images/icon-part/new16.svg'/></a>
                                    </div>
                                </Col>

                                <Col xs={8} sm={4}>
                                    <div className='participated-item res'>
                                        <a href='#'><img src='../images/icon-part/new-new-1.png'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item'>
                                        <a href='#'><img src='../images/icon-part/new-new-2.jpg'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item res'>
                                        <a href='#'><img src='../images/icon-part/new-new-3.png'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item res'>
                                        <a href='#'><img src='../images/icon-part/new-new-4.png'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item res'>
                                        <a href='#'><img src='../images/icon-part/new-new-5.png'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item res'>
                                        <a href='#'><img src='../images/icon-part/new-new-6.png'/></a>
                                    </div>
                                </Col>
                                <Col xs={8} sm={4}>
                                    <div className='participated-item res'>
                                        <a href='#'><img src='../images/icon-part/new-new-7.jpg'/></a>
                                    </div>
                                </Col>




                            </Slider>
                        </div>
                    </div>     
                </div>

                
                
            </div>
        </>
    )
}
export default Case